<template>
  <v-card :loading="loading" outlined>
    <ListTitle :access="false" title="Stock" :showBackButton="false" />
    <div id="stock-line"></div>
  </v-card>
</template>

<script>
import categoryService from "@/modules/Product/Category/service";
// import $ from "jquery";
import Highcharts from 'highcharts';

export default {
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      return categoryService.getAll().then(response => {
        if (response.data) {
          var newData = [];
          response.data.forEach(row => {
            newData.push([row.name, row.productBalance | 0]);
          });
          newData.sort(function(a, b) {
            return b[1] - a[1];
          });
          this.loading = false;

          Highcharts.chart("stock-line", {
            credits: false, // Disable HighCharts logo// Set Colors
            chart: {
              type: "column"
            },
            title: {
              text: null
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y:.1f}</b>"
            },
            plotOptions: {
              showInLegend: false
            },
            xAxis: {
              type: "category",              
              labels: {
                rotation: -45,
                style: {
                  fontSize: "13px",
                  fontFamily: "Verdana, sans-serif"
                }
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: "Phone Count"
              }
            },
            legend: {
              enabled: false
            },
            series: [
              {
                name: "Status",
                color: '#FBC02D',
                data: newData,
                dataLabels: {
                  enabled: true,
                  format: "{point.y:.0f}", // one decimal
                  style: {
                    fontSize: "13px",
                    fontFamily: "Verdana, sans-serif"
                  }
                }
              }
            ]
          });
        }
      });
    }
  }
};
</script>
