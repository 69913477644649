<template>
  <div>
    <v-container grid-list-lg fluid>
      <v-row>
        <v-col sm="12" md="6">
          <Product />
        </v-col>
        <v-col sm="12" md="6">
          <Category />
        </v-col>
        <v-col cols="12">
          <CategoryStock />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CategoryStock from "../Chart/CategoryStock";
import Product from "@/modules/Product/Tile/ProductCount.vue"
import Category from "@/modules/Product/Tile/CategoryCount.vue"

export default {
  components: {
    CategoryStock,
    Product,
    Category,
  },
  created() {
    document.title = "smplsale - Inventory";
  },
};
</script>

<style scoped></style>
