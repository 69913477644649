<template>
  <v-card
    color="yellow lighten-2"
    dark
    hover
    outlined
    flat
    @click="$router.push({ path: '/product/category/list' })"
  >
    <v-card-text style="height: 128px;">
      <v-layout
        class="white--text"
        align-center="align-center"
        justify-center="justify-center"
        column="column"
        fill-height="fill-height"
      >
        <v-progress-circular
          v-if="isLoading"
          size="50"
          width="5"
          color="white"
          indeterminate="indeterminate"
        ></v-progress-circular>
        <animated-number
          v-else
          class="black--text display-3 font-weight-light"
          :value="count"
          :formatValue="formatToNumber"
          :duration="1000"
        >
        </animated-number>
        <div class="text-center body-1 black--text">Category Count</div>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
import categoryService from "@/modules/Product/Category/service";
export default {
  name: "category-tile",
  data() {
    return {
      isLoading: true,
      count: 0,
    };
  },
  components: {
    AnimatedNumber,
  },
  created() {
    this.getList();
  },
  methods: {
    formatToNumber(value) {
      return `${value.toFixed(0)}`;
    },
    getList() {
      return categoryService.getAll().then((response) => {
        this.isLoading = false;
        this.count = response.count;

        return response;
      });
    },
  },
};
</script>

<style scoped></style>
